<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-dialog :value="true" max-width="550" transition="dialog-bottom-transition" persistent>
    <v-card>
      <v-icon color="white" small class="close-submit-dialog" @click="close">$close</v-icon>
      <v-list class="pa-0">
        <v-img class="p-abs img-header" src="@/assets/img/login-default-bg.png" min-width="250" width="100%"
               max-height="70px"
        />
        <div class="title-header">
          <v-list-item-title class="text-h5 font-weight-light white--text px-5 p-rel white--text pt-2">
            Questionnaire
          </v-list-item-title>
          <v-list-item-subtitle class="text-caption font-alt mb-1 px-5 white--text p-rel">
            {{ survey ? 'Editer le questionnaire' : 'Créer un nouveau questionnaire' }}
          </v-list-item-subtitle>
        </div>
        <div class="splitter" />
        <v-form ref="survey" lazy-validation class="mt-2 pb-3 px-3" @submit.prevent="submit">
          <v-subheader>Informations générales</v-subheader>
          <v-row class="ma-0">
            <v-col cols="12">
              <text-field v-model="model.name" hide-details="auto" outlined dense rounded
                          label="Nom du questionnaire" :rules="[$rules.required, $rules.maxLength(200)]"
                          hint="Ce nom n'est pas visible par le patient" persistent-hint
              />
            </v-col>
            <v-col cols="12">
              <services-autocomplete v-model="model.services" label="Service(s) rattaché(s)" :rules="[$rules.required]"
                                     persistent-hint hint="Un même questionnaire peut être attribué à plusieurs services"
                                     :disabled="isActive"
              />
            </v-col>
            <v-col cols="12">
              <text-field v-model.number="model.answerStartDelay" label="Délai d'envoi"
                          hide-details="auto" outlined dense rounded min="0" max="380" type="number"
                          :rules="[$rules.required, $rules.between(0, 380)]"
                          hint="En jours, l'intervalle entre la date d'entrée et l'envoi du questionnaire"
                          persistent-hint
              />
            </v-col>
            <v-col cols="12">
              <text-field v-model.number="model.closingDelay" label="Délai avant expiration"
                          hide-details="auto" outlined dense rounded min="0" max="380" type="number"
                          :rules="[$rules.required, $rules.between(1, 380)]"
                          persistent-hint hint="En jours, délai avant expiration d'un questionnaire"
              />
            </v-col>
          </v-row>
          <v-subheader>Notification au patient</v-subheader>
          <v-row class="ma-0">
            <v-col cols="6">
              <v-select v-model="model.notificationTypes" label="Canal de notification"
                        hide-details="auto" multiple outlined dense rounded
                        :items="notificationTypes" :rules="[$rules.required]"
              >
                <template v-slot:append>
                  <v-icon small :color="model.notificationTypes && model.notificationTypes.length ? 'success' : 'red lighten-3'" class="pt-2">$warning</v-icon>
                </template>
              </v-select>
            </v-col>
            <v-col cols="6">
              <time-picker v-model="model.answerNotificationHour" label="Heure d'envoi" hide-details="auto"
                           outlined dense rounded :rules="[$rules.required]"
              />
            </v-col>
          </v-row>
          <v-subheader>Relance du patient</v-subheader>
          <v-row class="ma-0">
            <v-col cols="6">
              <v-text-field v-model.number="model.reminderDelay" label="Délai avant relance" :rules="[$rules.between(0, 380)]"
                            hide-details="auto" outlined dense rounded type="number" persistent-hint
                            hint="En jours, délai avant relance du patient pour compléter le questionnaire"
              />
            </v-col>
            <v-col cols="6">
              <time-picker v-model="model.reminderNotificationHour" label="Heure de relance"
                           hide-details="auto" outlined dense rounded
              />
            </v-col>
            <v-col cols="12" class="d-none">
              <v-select :value="$store.state.establishments.activeEstablishmentId" label="Etablissement"
                        color="secondary" outlined dense rounded hide-details="auto" disabled
                        item-text="label" item-value="id" return-object
                        :items="[$store.getters['establishments/activeEstablishment']]"
              />
            </v-col>
          </v-row>
          <v-card-actions class="px-3 pt-6">
            <v-spacer />
            <v-btn x-small rounded text @click="close">
              Annuler
            </v-btn>
            <v-btn x-small rounded color="success" type="submit" :loading="submitting" :disabled="submitting">
              Enregistrer
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
  import TextField from '@/modules/core/components/TextField'
  import ServicesAutocomplete from '@/modules/services/components/ServicesAutocomplete'
  import TimePicker from '@/modules/core/components/TimePicker'
  import {create, update} from '@/modules/surveys/api/surveys'
  import {column} from '@/utils/array'

  export default {
    name: 'SurveyDialog',
    components: {TimePicker, ServicesAutocomplete, TextField},
    props: {
      survey: Object,
      isActive: Boolean
    },
    data() {
      return {
        model: {
          name: this.survey ? this.survey.name : '',
          answerStartDelay: this.survey ? parseInt(this.survey.answerStartDelay) : 1,
          closingDelay: this.survey ? parseInt(this.survey.closingDelay) : 1,
          answerNotificationHour: this.survey && typeof this.survey.answerNotificationHour === 'string' ? this.survey.answerNotificationHour.substr(0, 5) : '',
          notificationTypes: this.survey ? this.survey.notificationTypes : null,
          services: this.survey ? this.survey.services : [],
          reminderDelay: this.survey ? parseInt(this.survey.reminderDelay) : 1,
          reminderNotificationHour: this.survey && typeof this.survey.reminderNotificationHour === 'string' ? this.survey.reminderNotificationHour.substr(0, 5) : ''
        },
        submitting: false,
        notificationTypes: [
          {text: 'Email', value: 'email'},
          {text: 'SMS', value: 'sms'}
        ]
      }
    },
    methods: {
      async submit() {
        if (!this.$refs.survey.validate()) {
          return false
        }
        try {
          this.submitting = true

          let data = {...this.model}
          data.answerNotificationHour = data.answerNotificationHour ? data.answerNotificationHour + ':00' : null
          data.reminderNotificationHour = data.reminderNotificationHour ? data.reminderNotificationHour + ':00' : null
          data.services = column(this.model.services, '@id')

          let result = (this.survey ? await update(this.survey.id, data) : await create(data)).data

          if (!this.survey) {
            this.$emit('add-survey', result)
          } else {
            this.$emit('update-survey', result)
          }

          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/components/Snackbar'),
            type: 'success',
            title: this.survey ? 'Questionnaire modifié avec succès.' : 'Questionnaire crée avec succès.'
          })
          this.close()
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        } finally {
          this.submitting = false
        }
      },
      close() {
        this.$emit('close')
      }
    }
  }
</script>
