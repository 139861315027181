<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <div v-if="readOnly && selectedAlert">
    <v-avatar size="30" class="font-weight-bold text-h6 white--text">
      <font-awesome-icon :icon="['fad', 'exclamation-triangle']" size="1x" :class="selectedAlert.color" />
    </v-avatar>
    {{ selectedAlert.text }}
  </div>
  <v-select v-else-if="!readOnly" v-model="localValue" label="Déclencher une alerte" clearable :items="alerts" hide-details="auto">
    <template #prepend>
      <font-awesome-icon :icon="['fad', 'exclamation-triangle']" size="1x"
                         :class="selectedAlert ? selectedAlert.color : 'grey--text darken-4'"
      />
    </template>
    <template #item="{item}">
      <v-avatar size="30" class="font-weight-bold text-h6 white--text">
        <font-awesome-icon :icon="['fad', 'exclamation-triangle']" size="1x" :class="item.color" />
      </v-avatar>
      {{ item.text }}
    </template>
  </v-select>
</template>

<script>
  const alerts = [
    {text: 'Faible', value: 'low', color: 'yellow--text text--darken-4'},
    {text: 'Moyen', value: 'medium', color: 'orange--text text--darken-4'},
    {text: 'Haut', value: 'high', color: 'red--text text--darken-4'}
  ]

  export default {
    name: 'AlertEditor',
    inject: ['readOnly'],
    props: {
      value: String
    },
    data() {
      return {
        alerts
      }
    },
    computed: {
      localValue: {
        get() {
          return this.value
        }, set(value) {
          this.$emit('input', value)
        }
      },
      selectedAlert() {
        return this.alerts.find(alert => alert.value === this.localValue)
      }
    }
  }
</script>

<style scoped>

</style>
