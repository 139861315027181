<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-card :id="'step-'+value.id" class="my-5 elevation-x">
    <step-editor-header ref="header" :steps="steps" :step="localValue" :loading="context.loading"
                        v-on="$listeners"
    />
    <v-divider />
    <advice-editor v-if="advice" v-model="advice" class="mb-3"
                   :removing="advice.id === removingElementId"
                   :valid.sync="validStates.advice"
                   @remove="removeElement(advice)"
    />
    <question-editor v-if="question" v-model="question"
                     :removing="question.id === removingElementId"
                     :conditions.sync="conditions"
                     :valid.sync="validStates.question"
                     @remove="removeElement(question)"
    />
    <template v-if="question">
      <boolean-editor v-if="questionType === 'boolean'" v-model="conditions" :question="question" />
      <choice-editor v-else-if="questionType === 'choice'" :conditions.sync="conditions" :question="question"
                     :choices.sync="question.surveyQuestion.surveyChoices"
                     :valid.sync="validStates.response"
      />
      <scale-editor v-else-if="questionType === 'numeric'" v-model="conditions" :question="question"
                    :valid.sync="validStates.response"
      />
    </template>

    <template v-if="conditions.length === 0">
      <div class="pa-3">
        <div class="font-weight-bold pl-3">S'il n'y a pas de condition</div>
        <font-awesome-icon :icon="['fad', 'angle-double-right']" class="text--disabled d-inline-block" />
        <step-selector v-model="localValue.nextSurveyStep" class="d-inline-block ml-3" />
      </div>
    </template>

    <template v-if="!readOnly">
      <add-element-menu v-model="localValue" />
    </template>
  </v-card>
</template>

<script>
  import * as surveys from '@/modules/surveys/api'
  import logger from '@/services/logger'
  import StepSelector from '@/modules/surveys/components/editor/StepSelector'
  import AddElementMenu from '@/modules/surveys/components/editor/AddElementMenu'
  import { removeItem } from '@/utils/array'
  import StepEditorHeader from '@/modules/surveys/components/editor/StepEditorHeader'
  import ScaleEditor from '@/modules/surveys/components/editor/elements/scale/ScaleEditor'
  import QuestionEditor from '@/modules/surveys/components/editor/elements/QuestionEditor'
  import AdviceEditor from '@/modules/surveys/components/editor/elements/AdviceEditor'
  import BooleanEditor from '@/modules/surveys/components/editor/elements/BooleanEditor'
  import ChoiceEditor from '@/modules/surveys/components/editor/elements/ChoiceEditor'

  export default {
    name: 'StepEditor',
    components: {
      ChoiceEditor, BooleanEditor, ScaleEditor, StepEditorHeader, QuestionEditor, AdviceEditor, AddElementMenu,
      StepSelector
    },
    inject: ['readOnly'],
    provide() {
      return {
        step: this.value,
        setLoading: this.setLoading,
        removeElement: this.removeElement,
        context: this.context,
        removingElementId: this.removingElementId
      }
    },
    props: {
      position: Number,
      value: Object,
      steps: Array,
      valid: Boolean
    },
    data() {
      return {
        dragging: false, // necessary to deactivate the conditions transition group, otherwise the ghost will not work
        removingElementId: null,
        context: {
          loading: false
        },
        conditions: this.value.surveyElements.filter(formElement => formElement.type === 'condition'),
        validStates: {}
      }
    },
    computed: {
      localValue: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      question() {
        return this.localValue.surveyElements.find(formElement => formElement.type === 'question')
      },
      advice() {
        return this.localValue.surveyElements.find(formElement => formElement.type === 'advice')
      },
      questionType() {
        return this.question.surveyQuestion.type
      },
      localValid() {
        return !((this.advice && this.validStates.advice === false)
          || (this.question && this.validStates.question === false)
          || (this.question && ['choice', 'numeric'].includes(this.questionType) && this.validStates.response === false))
      }
    },
    watch: {
      localValid: {
        handler() {
          this.$emit('update:valid', this.localValid)
        },
        immediate: true
      },
      conditions(value) {
        this.localValue.surveyElements = this.localValue.surveyElements.filter(formElement => formElement.type !== 'condition').concat(value)
      },
      ['localValue.nextSurveyStep']: async function (newValue, oldValue) {
        if (oldValue !== newValue) {
          this.context.loading = true
          await surveys.steps.update(this.value.id, {
            nextSurveyStep: newValue
          })
          this.context.loading = false
        }
      }
    },
    methods: {
      setLoading(value) {
        this.loading = value
      },
      defineAsStart() {
        this.$refs.header.replaceStart()
      },
      async removeElement(element) {
        try {
          this.removingElementId = element.id
          await surveys.elements.delete(element.id)
          if (element.type === 'question') {
            this.conditions = []
          }
          removeItem(this.localValue.surveyElements, element)
        } catch (error) {
          logger.error(error)
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        } finally {
          this.removingElementId = null
        }
      }
    }
  }
</script>
