/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

import crud from '@/services/crud'
import api from '@/services/api'

export * from './surveys'
export const conditions = crud('/survey_conditions')
export const elements = crud('/survey_elements')
export const choices = crud('/survey_choices')
export const conditionParts = crud('/survey_condition_parts')
export const steps = crud('/survey_steps')
export const staySurveys = crud('/stay_surveys')
export const surveyResponses = crud('/survey_responses')
export const surveyElementResponses = crud('/survey_element_responses')

export function submitSurveyResponse(id) {
  return api.put(`/survey_responses/${id}/submit`)
}
