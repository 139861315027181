<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <div class="my-5">
    <transition-group name="slide-y-transition">
      <div v-for="(step, index) in sortedSteps" :key="step.id">
        <transition appear name="slide-x-transition">
          <div>
            <step-editor ref="steps" v-model="sortedSteps[index]" :position="index" :steps="sortedSteps"
                         :valid.sync="validStates[step.id]"
                         @remove="remove(step.id)"
                         @replace-start="done => replaceStart(step, done)"
                         @move-up="moveStep(step, step.position-1)" @move-down="moveStep(step,step.position+1)"
            />
            <template v-if="!readOnly">
              <add-step-button :position="index + 2" />
            </template>
            <v-skeleton-loader v-if="context.creatingStep === index + 1" type="list-item-avatar, divider, article"
                               class="mt-3"
            />
          </div>
        </transition>
      </div>
    </transition-group>
    <template v-if="!readOnly">
      <add-step-button v-if="data.surveySteps.length === 0" :position="data.surveySteps.length + 1" />
    </template>
    <v-skeleton-loader v-if="context.creatingStep === 0" class="mt-3" />
  </div>
</template>

<script>
  import StepEditor from '@/modules/surveys/components/editor/StepEditor'
  import { deepCopy } from '@/utils/object'
  import * as surveys from '@/modules/surveys/api'
  import AddStepButton from '@/modules/surveys/components/editor/AddStepButton'

  export default {
    name: 'SurveyEditor',
    components: {AddStepButton, StepEditor},
    provide() {
      return {
        context: this.context,
        survey: this.data,
        readOnly: this.readOnly,
        steps: () => this.sortedSteps // The function allow steps to be reactive in injected components
      }
    },
    props: {
      survey: Object,
      valid: Boolean,
      readOnly: Boolean
    },
    data() {
      return {
        data: deepCopy(this.survey),
        validStates: {},
        context: {
          creatingStep: null
        }
      }
    },
    computed: {
      sortedSteps() {
        return [...this.data.surveySteps].sort((a, b) => a.position - b.position)
      },
      localValid() {
        for (let stepId in this.validStates) {
          if (this.sortedSteps && this.sortedSteps.find(step => step.id === stepId) && this.validStates[stepId] === false) {
            return false
          }
        }
        return true
      }
    },
    watch: {
      localValid: {
        handler(value) {
          this.$emit('update:valid', value)
        },
        immediate: true
      }
    },
    methods: {
      remove(id) {
        let removedStepIndex = this.data.surveySteps.findIndex(step => step.id === id)
        let removedStep = this.data.surveySteps[removedStepIndex]
        this.$delete(this.data.surveySteps, removedStepIndex)

        // When you delete a step, all the following lose a position (already done on the api side automatically)
        this.data.surveySteps.forEach(step => {
          if (step.position > removedStep.position) {
            --step.position
          }
        })

        // When the first step is deleted, we define the new step in the first position as the start of the survey
        this.$nextTick(() => {
          if (removedStep.position === 1 && this.$refs.steps.length > 0) {
            this.$refs.steps[0].defineAsStart()
          }
        })
      },
      async moveStep(step, position) {
        await surveys.steps.update(step.id, {
          position
        })

        this.sortedSteps.filter(item => item.position < step.position && item.position >= position).forEach(item => ++item.position)
        this.sortedSteps.filter(item => item.position > step.position && item.position <= position).forEach(item => --item.position)
        step.position = position
      },
      async replaceStart(step, done) {
        try {
          await surveys.steps.update(step.id, {
            position: 1
          })

          this.data.surveySteps.filter(item => item.position < step.position && item.position >= 1).forEach(item => ++item.position)
          this.data.surveySteps.filter(item => item.position > step.position && item.position <= 1).forEach(item => --item.position)

          this.$set(step, 'position', 1)
        } finally {
          done()
        }
      }
    }
  }
</script>
