<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-menu v-if="!question || !advice" bottom offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn small color="transparent "
             class="font-alt btn-add mr-2 py-6 o-80 w-100"
             elevation="0" v-bind="attrs" v-on="on"
      >
        <font-awesome-icon :icon="['fad','plus-octagon']" class="mr-2 primary--text" />
        Ajouter un élément
      </v-btn>
    </template>

    <v-list>
      <template v-if="!question">
        <v-list-item @click.prevent="addQuestion('text')">
          <v-list-item-icon class="mr-2">
            <font-awesome-icon :icon="['fad','question']" class="fa-fw text--disabled" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Une question avec réponse libre</v-list-item-title>
            <v-list-item-subtitle>
              Une question pouvant être répondue avec un texte libre
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click.prevent="addQuestion('boolean')">
          <v-list-item-icon class="mr-2">
            <font-awesome-icon :icon="['fad','question']" class="fa-fw text--disabled" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Une question fermée</v-list-item-title>
            <v-list-item-subtitle>
              Une question pouvant être répondue par oui ou non
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click.prevent="addQuestion('choice')">
          <v-list-item-icon class="mr-2">
            <font-awesome-icon :icon="['fad','question']" class="fa-fw text--disabled" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Un choix</v-list-item-title>
            <v-list-item-subtitle>
              Une question avec plusieurs choix de réponses
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click.prevent="addQuestion('numeric')">
          <v-list-item-icon class="mr-2">
            <font-awesome-icon :icon="['fad','question']" class="fa-fw text--disabled" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Une échelle linéraire</v-list-item-title>
            <v-list-item-subtitle>
              Une question pouvant être répondue avec une valeur sur une échelle linéaire
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list-item v-if="!advice" @click.prevent="addAdvice">
        <v-list-item-icon class="mr-2">
          <font-awesome-icon :icon="['fad','info']" class="fa-fw text--disabled" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Un conseil</v-list-item-title>
          <v-list-item-subtitle>
            Element de type information, sans choix de réponse possible
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import * as surveys from '@/modules/surveys/api'

  export default {
    name: 'AddElementMenu',
    inject: ['step', 'steps'],
    props: {
      value: Object
    },
    computed: {
      localValue: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      question() {
        return this.localValue.surveyElements.find(formElement => formElement.type === 'question')
      },
      advice() {
        return this.localValue.surveyElements.find(formElement => formElement.type === 'advice')
      }
    },
    methods: {
      async addAdvice() {
        const formElement = (await surveys.elements.create({
          type: 'advice',
          surveyAdvice: {
            content: ''
          },
          surveyStep: this.value.id,
          position: 1
        })).data
        this.localValue.surveyElements.push(formElement)
      },
      async addQuestion(type) {
        const payload = {
          type: 'question',
          surveyStep: this.value.id,
          position: 2
        }

        let typePayload = null

        if(type === 'text') {
          typePayload = {
            surveyQuestion: {
              type: 'text',
              mandatory: false,
              label: '',
              name: ''
            }
          }
        } else if(type === 'boolean') {
          typePayload = {
            surveyQuestion: {
              type: 'boolean',
              mandatory: false,
              label: '',
              name: ''
            }
          }
        } else if(type === 'choice') {
          typePayload = {
            surveyQuestion: {
              type: 'choice',
              mandatory: false,
              label: '',
              name: '',
              possibleSurveyChoicesMax: 1
            }
          }
        } else if(type === 'numeric') {
          typePayload = {
            surveyQuestion: {
              type: 'numeric',
              mandatory: false,
              label: '',
              name: '',
              display: 'scale',
              minValue: null,
              maxValue: null,
              valueStep: 1
            }
          }
        }

        const formElement = (await surveys.elements.create({...payload, ...typePayload})).data
        this.localValue.surveyElements.push(formElement)
      }
    }
  }
</script>
