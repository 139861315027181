<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-form ref="form">
    <scale-interval-editor
      v-for="interval in sortedIntervals"
      :id="interval.id"
      :key="interval.id"
      :condition-id="interval.conditionId"
      :removing="removingFormElementId === interval.id"
      :min-id="interval.minId"
      :max-id="interval.maxId"
      :min.sync="interval.min"
      :max.sync="interval.max"
      :alert-level.sync="interval.alertLevel"
      :next-step-id.sync="interval.nextStepId"
      :intervals="intervals"
      :valid.sync="validStates[interval.id]"
      @remove="removeInterval(interval.id)"
      @update="onIntervalUpdate(interval)"
    />
    <v-btn v-if="!readOnly" x-small rounded class="ma-5" color="primary" :loading="creating"
           @click.prevent="createInterval"
    >
      Ajouter un intervalle
    </v-btn>
  </v-form>
</template>

<script>
  import * as surveys from '@/modules/surveys/api'
  import { removeItem } from '@/utils/array'
  import ScaleIntervalEditor from '@/modules/surveys/components/editor/elements/scale/ScaleIntervalEditor'

  export default {
    name: 'ScaleEditor',
    components: {ScaleIntervalEditor},
    inject: ['step', 'readOnly'],
    props: {
      value: Array,
      question: Object,
      valid: Boolean
    },
    data() {
      return {
        removingFormElementId: null,
        creating: false,
        removingInterval: null,
        intervals: [],
        validStates: {}
      }
    },
    computed: {
      localValue: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      sortedIntervals() {
        return [...this.intervals].sort((a, b) => {
          if (a.min < b.min) {
            return -1
          }
          if (a.min > b.min) {
            return 1
          }
          return 0
        })
      },
      intervalById() {
        return id => this.intervals.find(interval => interval.id === id)
      },
      localValid() {
        for (let intervalId in this.validStates) {
          if (this.intervals.find(interval => interval.id === intervalId) && this.validStates[intervalId] === false) {
            return false
          }
        }
        return true
      }
    },
    watch: {
      value: {
        handler(value) {
          this.intervals = value.map(formElement => {
            const minPart = formElement.surveyCondition.surveyConditionParts.find(part => part.operator === 'GREATER_THAN_OR_EQUAL')
            const maxPart = formElement.surveyCondition.surveyConditionParts.find(part => part.operator === 'LESSER_THAN_OR_EQUAL')
            return {
              id: formElement.id,
              conditionId: formElement.surveyCondition.id,
              minId: minPart.id,
              min: minPart.conditionValue,
              maxId: maxPart.id,
              max: maxPart.conditionValue,
              nextStepId: formElement.surveyCondition.successSurveyStep,
              alertLevel: formElement.surveyCondition.successSurveyAlertLevel
            }
          })
        },
        immediate: true
      },
      localValid: {
        handler(value) {
          this.$emit('update:valid', value)
        },
        immediate: true
      }
    },
    created() {
      if (this.intervals.length === 0) {
        this.createInterval()
      }
    },
    methods: {
      async createInterval() {
        try {
          this.creating = true

          const formElement = (await surveys.elements.create(    {
            type: 'condition',
            surveyCondition: {
              type: 'and',
              successSurveyStep: null,
              failureSurveyStep: null,
              successSurveyAlertLevel: null,
              failureSurveyAlertLevel: null
            },
            surveyStep: this.step.id,
            position: this.step.surveyElements.length
          })).data

          const minPart = (await surveys.conditionParts.create({
            type: 'OPERATOR',
            operator: 'GREATER_THAN_OR_EQUAL',
            conditionValue: '1',
            conditionValueType: 'INTEGER',
            surveyElementTarget: this.question.id,
            surveyCondition: formElement.surveyCondition.id
          })).data

          const maxPart = (await surveys.conditionParts.create({
            type: 'OPERATOR',
            operator: 'LESSER_THAN_OR_EQUAL',
            conditionValue: '10',
            conditionValueType: 'INTEGER',
            surveyElementTarget: this.question.id,
            surveyCondition: formElement.surveyCondition.id
          })).data

          const condition = formElement.surveyCondition
          condition.surveyConditionParts = [minPart, maxPart]
          formElement.surveyCondition = condition

          this.localValue.push(formElement)
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        } finally {
          this.creating = false
        }
      },
      async removeInterval(formElementId) {
        this.removingFormElementId = formElementId
        await surveys.elements.delete(formElementId)
        removeItem(this.localValue, this.localValue.find(element => element.id === formElementId))
        this.removingFormElementId = null
      },
      onIntervalUpdate(interval) {
        let formElement = this.localValue.find(element => element.id === interval.id)
        let maxPart = formElement.surveyCondition.surveyConditionParts.find(part => part.operator === 'LESSER_THAN_OR_EQUAL')
        let minPart = formElement.surveyCondition.surveyConditionParts.find(part => part.operator === 'GREATER_THAN_OR_EQUAL')

        minPart.conditionValue = interval.min
        maxPart.conditionValue = interval.max
        formElement.surveyCondition.successSurveyAlertLevel = interval.alertLevel
        formElement.surveyCondition.successSurveyStep = interval.nextStepId
      }
    }
  }
</script>
