<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-list>
    <v-list-item v-for="(choice, index) in localChoices" :key="choice.id">
      <v-row align="baseline">
        <v-col cols="12" md="5">
          <div class="d-flex align-baseline">
            Si
            <v-text-field ref="choiceLabel" v-model="choice.label" hide-details="auto" outlined dense rounded
                          class="ml-2"
                          :label="'Choix ' + (index + 1)"
                          :readonly="readOnly"
                          :rules="[$rules.required, $rules.maxLength(255)]"
                          :counter="choice.label.length > 255"
                          :counter-value="() => choice.label.length+'/'+255"
                          @input="value => updateChoiceLabel(choice, value, index)"
            />
          </div>
        </v-col>
        <v-col cols="6" md="4">
          <font-awesome-icon :icon="['fad', 'angle-double-right']" class="text--disabled d-inline-block mr-2" />
          <step-selector v-model="choiceCondition(choice).surveyCondition.successSurveyStep"
                         class="d-inline-block"
                         @input="value => updateSurveyCondition(choiceCondition(choice), 'successSurveyStep', value)"
          />
        </v-col>
        <v-col cols="6" md="3" class="text--right">
          <alert-editor v-model="choiceCondition(choice).surveyCondition.successSurveyAlertLevel"
                        @input="value => updateSurveyCondition(choiceCondition(choice), 'successSurveyAlertLevel', value)"
          />
        </v-col>
      </v-row>
      <v-list-item-action v-if="!readOnly">
        <v-btn small icon :loading="removingChoice === choice" @click.prevent="removeChoice(choice)">
          <font-awesome-icon :icon="['fal','times']" size="lg" />
        </v-btn>
      </v-list-item-action>
    </v-list-item>

    <v-list-item-action>
      <v-btn v-if="!readOnly" x-small rounded color="primary" :loading="creatingChoice" @click.prevent="createChoice">
        Ajouter un choix
      </v-btn>
    </v-list-item-action>
  </v-list>
</template>

<script>
  import * as surveys from '@/modules/surveys/api'
  import { removeItem } from '@/utils/array'
  import StepSelector from '@/modules/surveys/components/editor/StepSelector'
  import AlertEditor from '@/modules/surveys/components/editor/elements/AlertEditor'
  import { debounce } from 'throttle-debounce'

  export default {
    name: 'ChoiceEditor',
    components: {AlertEditor, StepSelector},
    inject: ['step', 'context', 'readOnly'],
    props: {
      question: Object,
      conditions: Array,
      choices: Array,
      valid: Boolean
    },
    data() {
      return {
        creatingChoice: false,
        removingChoice: null,
        validStates: {}
      }
    },
    computed: {
      localChoices: {
        get() {
          return this.choices || []
        },
        set(value) {
          return this.$emit('update:choices', value)
        }
      },
      localConditions: {
        get() {
          return this.conditions || []
        },
        set(value) {
          return this.$emit('update:conditions', value)
        }
      },
      choiceCondition() {
        return choice => this.localConditions.find(condition => {
          return condition.surveyCondition.surveyConditionParts[0] && choice.id === condition.surveyCondition.surveyConditionParts[0].surveyChoice
        })
      },
      localValid() {
        for (let choiceId in this.validStates) {
          if (this.choices && this.choices.find(choice => choice.id === choiceId) && this.validStates[choiceId] === false) {
            return false
          }
        }
        return true
      }
    },
    watch: {
      localValid: {
        handler(value) {
          this.$emit('update:valid', value)
        },
        immediate: true
      }
    },
    async created() {
      if (!this.choices || !this.choices.length) {
        await this.createChoice()
        await this.createChoice()
      }
    },
    methods: {
      async createChoice() {
        try {
          this.creatingChoice = true
          const position = this.localChoices.length + 1

          const choice = (await surveys.choices.create({
            surveyQuestion: this.question.surveyQuestion.id,
            label: `Choix ${position}`,
            position
          })).data

          const conditionElement = (await surveys.elements.create(    {
            type: 'condition',
            surveyCondition: {
              type: 'simple',
              successSurveyStep: null,
              failureSurveyStep: null,
              successSurveyAlertLevel: null,
              failureSurveyAlertLevel: null
            },
            surveyStep: this.step.id,
            position: this.step.surveyElements.length
          })).data

          const condition = conditionElement.surveyCondition
          const part = (await surveys.conditionParts.create({
            type: 'CHOICE',
            surveyCondition: condition.id,
            operator: null,
            conditionValue: '1',
            conditionValueType: 'INTEGER',
            surveyElementTarget: this.question.id,
            surveyChoice: choice.id
          })).data

          condition.surveyConditionParts = [part]
          choice.label = ''

          condition.surveyConditionParts = [part]
          conditionElement.surveyCondition = condition

          this.localConditions.push(conditionElement)
          this.localChoices.push(choice)
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        } finally {
          this.creatingChoice = false
        }
      },
      async removeChoice(choice) {
        this.removingChoice = choice
        let condition = this.choiceCondition(choice)
        await surveys.choices.delete(choice.id)
        removeItem(this.localChoices, choice)
        removeItem(this.localConditions, condition)
        this.removingChoice = null
      },
      updateChoiceLabel: debounce(500, async function (choice, value, index) {
        let valid = this.$refs.choiceLabel[index].validate()
        this.$set(this.validStates, choice.id, valid)
        if (!valid) {
          return
        }

        this.context.loading = true
        await surveys.choices.update(choice.id, {
          label: value
        })
        this.context.loading = false
      }),
      async updateSurveyCondition(surveyCondition, field, value) {
        this.context.loading = true
        await surveys.elements.update(surveyCondition.id ,{
          surveyCondition: {
            [field]: value
          }
        })
        this.context.loading = false
      }
    }
  }
</script>
