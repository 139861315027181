<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-autocomplete ref="autocomplete"
                  v-model="localValue"
                  :loading="searching" :search-input.sync="search"
                  item-value="id" :rules="rules"
                  item-text="label"
                  hide-no-data outlined dense rounded
                  hide-details="auto"
                  no-filter
                  :items="items"
                  :label="label"
                  return-object
                  multiple v-bind="$attrs"
                  :disabled="disabled"
                  @update:error="onError"
  >
    <template v-slot:item="{ item, on, attrs }">
      <v-list-item transition="slide-y-transition" v-bind="attrs" v-on="on">
        <v-list-item-content>
          <v-list-item-title>{{ item.label }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-if="errorIcon" v-slot:append>
      <v-icon small :color="isError ? 'red lighten-3' : 'success'" class="pt-2">$warning</v-icon>
    </template>
  </v-autocomplete>
</template>

<script>
  import * as services from '@/modules/services/api/services'
  import {empty} from '@/utils/variables'

  export default {
    name: 'ServicesAutocomplete',
    props: {
      rules: Array,
      errorIcon: {
        type: Boolean,
        // eslint-disable-next-line vue/no-boolean-default
        default: function () {
          return !empty(this.rules)
        }
      },
      disabled: Boolean,
      error: Boolean,
      value: Array,
      label: {
        type: String,
        default: 'Rechercher un service'
      }
    },
    data() {
      return {
        searching: false,
        items: this.value || [],
        search: null,
        localError: false
      }
    },
    computed: {
      isError() {
        return this.error || this.localError
      },
      localValue: {
        set(value) {
          this.$emit('input', value)
        },
        get() {
          return this.value
        }
      }
    },
    created() {
      this.loadServices()
    },
    mounted() {
      this.localError = !this.$refs.autocomplete.validate()
    },
    methods: {
      onError(value) {
        this.localError = value
      },
      async loadServices() {
        this.$emit('change')
        try {
          this.searching = true
          this.items = (await services.list({
            params: {
              establishment: this.$store.state.establishments.activeEstablishmentId
            }
          })).data['hydra:member']
        } catch (e) {
          this.$root.$emit('error', e)
        } finally {
          this.searching = false
        }
      }
    }
  }
</script>
