<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <div v-if="!editMode">
    <template v-if="value === null || !selectedOption">
      Allez à la fin du questionnaire
    </template>
    <template v-else>
      Aller à l'étape
      <v-btn icon small @click.prevent="$vuetify.goTo('#step-'+selectedOption.value, {offset: 60})">
        <v-avatar color="primary" size="24">
          <span class="white--text"> {{ selectedOption.text }}</span>
        </v-avatar>
      </v-btn>
    </template>

    <v-btn v-if="!readOnly" small icon color="primary" @click.prevent="edit">
      <font-awesome-icon :icon="['fad','pencil']" class="success--text" />
    </v-btn>
  </div>
  <v-select v-else ref="select" v-model="localValue" label="Allez à l'étape" :items="stepsOptions"
            @input="editMode = false" @blur="editMode = false"
  />
</template>

<script>
  export default {
    name: 'StepSelector',
    inject: ['steps', 'step', 'readOnly'],
    props: {
      value: String
    },
    data() {
      return {
        editMode: false
      }
    },
    computed: {
      localValue: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      selectedOption() {
        return this.localValue ? this.stepsOptions.find(option => option.value === this.localValue) : null
      },
      stepsOptions() {
        let options = []
        let steps = this.steps()
        for (let i in steps) {
          options.push({
            text: steps[i].position,
            value: steps[i].id,
            disabled: i <= this.steps().indexOf(this.step)
          })
        }
        options.push({
          text: 'Fin du questionnaire',
          value: null
        })
        return options
      }
    },
    watch: {
      ['step.position']() {
        // If the step has been moved, reset the invalid step selectors to null
        if (this.selectedOption && this.selectedOption.disabled) {
          this.localValue = null
        }
      }
    },
    methods: {
      edit() {
        this.editMode = true
        this.$nextTick(() => {
          this.$refs.select.focus()
          this.$refs.select.activateMenu()
        })
      }
    }
  }
</script>

<style scoped>

</style>
