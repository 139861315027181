<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-list>
    <v-list-item>
      <v-textarea ref="input" v-model="localValue.surveyAdvice.content" hide-details="auto" outlined dense placeholder="Un conseil"
                  :readonly="readOnly" :rules="[$rules.maxLength(500)]"
                  :counter="localValue.surveyAdvice.content.length > 500" :counter-value="() => localValue.surveyAdvice.content.length+'/'+500"
      >
        <template v-if="context.loading" v-slot:append>
          <v-progress-circular color="primary" size="16" width="2" indeterminate class="mt-1" />
        </template>
        <template v-if="!readOnly" v-slot:append-outer>
          <v-btn small icon :loading="removingElementId === value.id" @click.prevent="$emit('remove')">
            <font-awesome-icon :icon="['fal','times']" size="lg" />
          </v-btn>
        </template>
      </v-textarea>
    </v-list-item>
  </v-list>
</template>

<script>
  import { debounce } from 'throttle-debounce'
  import { elements } from '@/modules/surveys/api'

  export default {
    name: 'AdviceEditor',
    inject: ['step', 'context', 'removingElementId', 'removeElement', 'readOnly'],
    props: {
      value: Object,
      valid: Boolean
    },
    data() {
      return {
        removing: false
      }
    },
    computed: {
      localValue: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      }
    },
    watch: {
      ['localValue.surveyAdvice.content']: debounce(500, async function (newValue, oldValue) {
        if (oldValue !== newValue) {
          let valid = this.$refs.input.validate()
          this.$emit('update:valid', valid)
          if(!valid) {
            return
          }

          try {
            this.context.loading = true
            await elements.update(this.value.id,     {
              surveyAdvice: {
                content: newValue
              }
            })
          } catch (error) {
            this.$root.$emit('snackbar:open', {
              snackbar: () => import('@/modules/core/components/Snackbar'),
              type: 'error',
              title: 'Une erreur est survenue'
            })
          } finally {
            this.context.loading = false
          }
        }
      })
    },
    mounted() {
      this.$emit('update:valid', this.$refs.input.validate())
    }
  }
</script>
