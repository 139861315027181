<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-list-item two-line>
    <v-list-item-avatar>
      <v-avatar color="primary" size="48">
        <span class="white--text text-h5">{{ step.position }}</span>
      </v-avatar>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        <template v-if="step.position === 1">
          Première étape
          <font-awesome-icon :icon="['fas','flag-checkered']" size="sm" />
        </template>
        <template v-else>
          Étape
        </template>
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action v-if="!readOnly">
      <div class="d-inline-block">
        <v-btn v-if="step.position < steps.length && step.position > 1" small icon @click.prevent="$emit('move-down')">
          <font-awesome-icon :icon="['fal','arrow-down']" size="lg" />
        </v-btn>
        <v-btn v-if="step.position > 2" small icon @click.prevent="$emit('move-up')">
          <font-awesome-icon :icon="['fal','arrow-up']" size="lg" />
        </v-btn>

        <v-tooltip v-if="step.position > 1" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small icon :loading="replacingStart"
                   v-bind="attrs" @click.prevent="replaceStart" v-on="on"
            >
              <font-awesome-icon :icon="['fas','flag-checkered']" size="lg" />
            </v-btn>
          </template>
          <span>Définir comme début du questionnaire</span>
        </v-tooltip>

        <v-btn small icon :loading="removing" @click.prevent="remove">
          <font-awesome-icon :icon="['fal','times']" size="lg" />
        </v-btn>
      </div>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
  import * as surveys from '@/modules/surveys/api'
  import logger from '@/services/logger'

  export default {
    name: 'StepEditorHeader',
    inject: ['readOnly'],
    props: {
      steps: Array,
      step:Object,
      loading:Boolean
    },
    data() {
      return {
        replacingStart: false,
        removing: false
      }
    },
    methods:{
      async replaceStart() {
        this.replacingStart = true
        this.$emit('replace-start', () => {
          this.replacingStart = false
        })
      },
      async remove() {
        try {
          this.removing = true
          await surveys.steps.delete(this.step.id)

          this.$emit('remove')
        } catch (error) {
          logger.error(error)
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        } finally {
          this.removing = false
        }
      }
    }
  }
</script>
