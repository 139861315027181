<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-list>
    <v-list-item>
      <v-textarea ref="input" v-model="localValue.surveyQuestion.label" :readonly="readOnly" :rules="[$rules.maxLength(500)]"
                  :counter="localValue.surveyQuestion.label.length > 500" :counter-value="() => localValue.surveyQuestion.label.length+'/'+500"
                  rows="1" placeholder="..." label="Saisissez la question à poser" hide-details="auto" outlined dense auto-grow
      >
        <template v-if="context.loading" v-slot:append>
          <v-progress-circular color="primary" size="16" width="2" indeterminate class="mt-1" />
        </template>
        <template v-if="!readOnly" v-slot:append-outer>
          <v-btn small icon :loading="removing" class="mt-2" @click.prevent="$emit('remove')">
            <font-awesome-icon :icon="['fal','times']" size="lg" />
          </v-btn>
        </template>
      </v-textarea>
    </v-list-item>
    <span class="text-caption font-alt mx-4 pa-1 primary--text">
      <font-awesome-icon :icon="['fad', 'info-circle']" size="1x" class="primary--text mr-1" />
      La réponse attendue est {{ label }}
    </span>
  </v-list>
</template>

<script>
  import { debounce } from 'throttle-debounce'
  import * as surveys from '@/modules/surveys/api'

  export default {
    name: 'QuestionEditor',
    inject: ['context', 'readOnly'],
    props: {
      value: Object,
      removing: Boolean,
      valid: Boolean,
      conditions: Array
    },
    computed: {
      localValue: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      label() {
        let label
        switch (this.value.surveyQuestion.type) {
          case 'text':
            label = 'un texte libre'
            break
          case 'boolean':
            label = 'un choix entre Oui ou Non'
            break
          case 'choice':
            label = 'un choix parmi les valeurs suivantes'
            break
          case 'numeric':
            label = 'une valeur sur une échelle de 1 à 10'
            break
        }
        return label
      }
    },
    watch: {
      ['localValue.surveyQuestion.label']: debounce(500, async function (newValue, oldValue) {
        if (oldValue !== newValue) {
          let valid = this.$refs.input.validate()
          this.$emit('update:valid', valid)
          if(!valid) {
            return
          }

          try {
            this.context.loading = true
            await surveys.elements.update(this.value.id, {
              surveyQuestion: {
                label: newValue
              }
            })
          } catch (error) {
            this.$root.$emit('snackbar:open', {
              snackbar: () => import('@/modules/core/components/Snackbar'),
              type: 'error',
              title: 'Une erreur est survenue'
            })
          } finally {
            this.context.loading = false
          }
        }
      })
    },
    mounted() {
      this.$emit('update:valid', this.$refs.input.validate())
    }
  }
</script>
