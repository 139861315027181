<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-btn small rounded color="transparent" class="font-alt btn-add o-80" elevation="0"
         :disabled="context.creatingStep !== null" :loading="context.creatingStep === position"
         @click.prevent="createStep"
  >
    <font-awesome-icon :icon="['fad','plus-octagon']" class="mr-2" />
    Ajouter une étape {{ position }}
  </v-btn>
</template>

<script>
  import * as surveys from '@/modules/surveys/api'

  export default {
    name: 'AddStepButton',
    inject: ['survey', 'context'],
    props: {
      position: Number
    },
    data() {
      return {
        submitting: false
      }
    },
    methods: {
      async createStep() {
        this.context.creatingStep = this.position
        let step = (await surveys.steps.create({
          survey: this.survey.id,
          position: this.position
        })).data

        step.surveyElements = []

        this.survey.surveySteps.filter(item => item.position >= this.position).forEach(item => ++item.position)

        this.survey.surveySteps.push(step)
        this.context.creatingStep = null
      }
    }
  }
</script>
