<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <div v-if="condition" class="pa-6">
    <v-row align="baseline">
      <v-col cols="auto">
        Si <span class="font-weight-bold">OUI</span>
      </v-col>
      <v-col>
        <font-awesome-icon :icon="['fad', 'angle-double-right']" class="text--disabled d-inline-block mr-2" />
        <step-selector v-model="condition.successSurveyStep" class="d-inline-block" @input="value => updateCondition('successSurveyStep', value)" />
      </v-col>
      <v-col cols="3" class="text--right">
        <alert-editor v-model="condition.successSurveyAlertLevel" @input="value => updateCondition('successSurveyAlertLevel', value)" />
      </v-col>
    </v-row>

    <v-row align="baseline">
      <v-col cols="auto">
        Si <span class="font-weight-bold">NON</span>
      </v-col>
      <v-col>
        <font-awesome-icon :icon="['fad', 'angle-double-right']" class="text--disabled d-inline-block mr-2" />
        <step-selector v-model="condition.failureSurveyStep" class="d-inline-block" @input="value => updateCondition('failureSurveyStep', value)" />
      </v-col>
      <v-col cols="3" class="text--right">
        <alert-editor v-model="condition.failureSurveyAlertLevel" @input="value => updateCondition('failureSurveyAlertLevel', value)" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import StepSelector from '@/modules/surveys/components/editor/StepSelector'
  import AlertEditor from '@/modules/surveys/components/editor/elements/AlertEditor'
  import * as surveys from '@/modules/surveys/api'

  export default {
    name: 'BooleanEditor',
    components: {AlertEditor, StepSelector},
    inject: ['step', 'context'],
    props: {
      value: Array,
      question: Object
    },
    data() {
      return {
        condition: this.value.length ? this.value[0].surveyCondition : null,
        conditionElementId: this.value.length ? this.value[0].id : null
      }
    },
    computed: {
      localValue: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      }
    },
    async created() {
      if (!this.condition) {
        const formElement = (await surveys.elements.create(    {
          type: 'condition',
          surveyCondition: {
            type: 'simple',
            successSurveyStep: null,
            failureSurveyStep: null,
            successSurveyAlertLevel: null,
            failureSurveyAlertLevel: null
          },
          surveyStep: this.step.id,
          position: this.step.surveyElements.length
        })).data

        const part = (await surveys.conditionParts.create({
          type: 'OPERATOR',
          operator: 'EQUAL',
          conditionValue: '1',
          conditionValueType: 'BOOLEAN',
          surveyElementTarget: this.question.id,
          surveyCondition: formElement.surveyCondition.id
        })).data

        const condition = formElement.surveyCondition
        condition.surveyConditionParts = [part]
        this.condition = condition
        formElement.surveyCondition = condition
        this.$emit('input', [formElement])
      }
    },
    methods: {
      async updateCondition(field, value) {
        try {
          this.context.loading = true
          await surveys.elements.update(this.value[0].id ,{
            surveyCondition: {
              [field]: value
            }
          })
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        } finally {
          this.context.loading = false
        }
      }
    }
  }
</script>
