<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-form ref="form">
    <v-row align="baseline" class="pl-8 pr-5">
      <span class="font-weight-bold">De</span>
      <v-col cols="auto">
        <v-combobox hide-details no-filter outlined dense rounded
                    :items="items"
                    :rules="[$rules.between(0, max), noOverlap]"
                    :value="min" class="mx-2"
                    :disabled="readOnly"
                    style="width: 100px !important" @input="value => $emit('update:min', value)"
        />
      </v-col>
      <span class="font-weight-bold">à</span>
      <v-col cols="auto">
        <v-combobox hide-details no-filter outlined dense rounded
                    :items="items"
                    :rules="[$rules.between(min, 10), noOverlap]"
                    :value="max" style="width: 100px !important" class="mx-2"
                    :disabled="readOnly"
                    @input="value => $emit('update:max', value)"
        />
      </v-col>
      <v-col>
        <font-awesome-icon :icon="['fad', 'angle-double-right']" class="text--disabled d-inline-block mr-2" />
        <step-selector :value="nextStepId" class="d-inline-block"
                       @input="value => $emit('update:nextStepId', value)"
        />
      </v-col>
      <v-col cols="3">
        <alert-editor :value="alertLevel" @input="value => $emit('update:alertLevel', value)" />
      </v-col>
      <v-col v-if="!readOnly" cols="auto">
        <v-btn small icon :loading="removing" @click.prevent="$emit('remove')">
          <font-awesome-icon :icon="['fal','times']" size="lg" />
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  import StepSelector from '@/modules/surveys/components/editor/StepSelector'
  import * as surveys from '@/modules/surveys/api'
  import AlertEditor from '@/modules/surveys/components/editor/elements/AlertEditor'

  export default {
    name: 'ScaleIntervalEditor',
    components: {StepSelector, AlertEditor},
    inject: ['readOnly'],
    props: {
      removing: Boolean,
      valid: Boolean,
      id: String,
      conditionId: String,
      min: [Number, String],
      minId: String,
      max: [Number, String],
      maxId: String,
      alertLevel: String,
      nextStepId: String,
      intervals: Array
    },
    data() {
      return {
        items: [...Array(10).keys()].map(i => i + 1)
      }
    },
    watch: {
      intervals: {
        handler() {
          this.$nextTick(() => {
            this.$emit('update:valid', this.$refs.form.validate())
          })
        },
        deep: true,
        immediate: true
      },
      min(value) {
        this.updateConditionPart(this.minId, value)
      },
      max(value) {
        this.updateConditionPart(this.maxId, value)
      },
      alertLevel(value) {
        this.updateCondition('successSurveyAlertLevel', value)
      },
      nextStepId(value) {
        this.updateCondition('successSurveyStep', value)
      }
    },
    methods: {
      updateConditionPart(conditionId, conditionValue) {
        this.$emit('update:valid', this.$refs.form.validate())

        this.$nextTick(async () => {
          await surveys.conditionParts.update(conditionId, {
            conditionValue: conditionValue.toString() // Api accepts only string ...
          })

          this.$emit('update')
        })
      },
      async updateCondition(field, value) {
        await surveys.elements.update(this.id, {
          surveyCondition: {
            [field]: value
          }
        })

        this.$emit('update')
      },
      noOverlap(value) {
        return !(this.intervals.some(interval => {
          return interval.id !== this.id && parseInt(value) >= parseInt(interval.min) && parseInt(value) <= parseInt(interval.max)
        })) || 'Cette valeur se chevauche avec une autre intervalle'
      }
    }
  }
</script>
